import { Action } from '../actions/actionTypes';
import STORE from '../store';
import { AppStore } from '../types';

// eslint-disable-next-line default-param-last
const reducer = (state = STORE.app, action: Action): AppStore => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
