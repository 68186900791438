import image from './styles/background_synthesis.png';

const App: React.FC<{}> = function () {
    return (
        <div className='body'>
            <div className='image-wrapper'>
                <img src={image} />
                <a className='mail' href='mailto:info@personalized.at'>
                    info@personalized.at
                </a>
            </div>
        </div>
    );
};

export default App;
