import ReactDOM from 'react-dom';
import { createBrowserHistory, History } from 'history';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {
    ConnectedRouter,
    connectRouter,
    routerMiddleware
} from 'connected-react-router';
import App from './App';
import Reducers from './reducers/Reducers';
import STORE from './store';

import './styles/main.less';

const browserHistory = createBrowserHistory();
const middlewares: any[] = [thunk, routerMiddleware(browserHistory)];

if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
        collapsed: true,
        titleFormatter: action => `${action.type}`
    });
    middlewares.push(logger);
}

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        app: Reducers
    });

const configureStore = (initialState = STORE) =>
    createStore(
        createRootReducer(browserHistory),
        initialState,
        applyMiddleware(...middlewares)
    );

ReactDOM.render(
    <Provider store={configureStore()}>
        <ConnectedRouter history={browserHistory}>
            <App />
            <div className='footer'>
                Siskakis Fanourios, Gattergasse 2A/1/20, 1110 Wien, Österreich, UID-Nr.:
                ATU78239158
            </div>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
